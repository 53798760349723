import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/container';
import { CONSTANTS } from 'constants/styles/constants';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useTeamEventGallery } from './hooks';

const SSubContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1.5rem 1.5rem;

    & > div {
        border-radius: 1rem;
    }

    & > div:nth-child(1) {
        grid-column: 1 / 5;
    }

    & > div:nth-child(2) {
        grid-column: 5 / 7;
    }

    & > div:nth-child(3) {
        grid-column: 1 / 4;
    }

    & > div:nth-child(4) {
        grid-column: 4 / 7;
    }

    & > div:nth-child(5) {
        grid-column: 1 / 3;
    }

    & > div:nth-child(6) {
        grid-column: 3 / 7;
    }

    ${CONSTANTS.MEDIA.max1024`
        grid-template-columns: repeat(2, 1fr);

        & > div:nth-child(odd) {
            grid-column: 1 !important;
        }

        & > div:nth-child(even) {
            grid-column: 2 !important;
        }
    `}

    ${CONSTANTS.MEDIA.max700`
        grid-template-columns: auto;

        & > div:nth-child(even) {
            grid-column: 1 !important;
        }
    `}
`;

const resolveAltImage = (index) => `Team Event ${index}`;

export const TeamEventGallery = () => {
    const images = useTeamEventGallery();
    const resolveGallery = () =>
        Object.keys(images).map((key, index) => {
            const alt = resolveAltImage(index + 1);
            return (
                <GatsbyImage
                    key={alt}
                    alt={alt}
                    image={images[key].childImageSharp.gatsbyImageData}
                />
            );
        });

    return (
        <Container>
            <SSubContainer>{resolveGallery()}</SSubContainer>
        </Container>
    );
};
