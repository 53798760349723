import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderFourth } from 'components/header-fourth';
import { HeaderSecond } from 'components/header-second';
import { MainTitle } from 'components/main-title';
import { CONSTANTS } from 'constants/styles/constants';

const SHeaderFourth = styled(HeaderFourth)`
    color: var(--additional-color);
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    font-size: 0.75rem;
    text-align: center;
`;

const SHeaderSecond = styled(HeaderSecond)`
    text-align: center;
    font-size: 2.25rem;
    font-weight: 300;
    padding: 0 2rem;
    color: var(--white-color);

    ${CONSTANTS.MEDIA.max600`
        padding: 0;
    `}
`;

const SMainDescriptionEnd = styled.span`
    color: var(--additional-color);
`;

const STitle = styled(MainTitle)`
    margin: 0.75rem 0 2.25rem 0;
    text-align: center;
    color: var(--white-color);

    ${CONSTANTS.MEDIA.max600`
        font-size: 3.625rem;
    `}
`;

export const Description = () => (
    <Container>
        <SHeaderFourth>
            <FormattedMessage id="view-team.topSubTitle" />
        </SHeaderFourth>
        <STitle withAfterSelector={false}>
            <FormattedMessage id="view-team.mainTitle" />
        </STitle>
        <SHeaderSecond>
            <div>
                <FormattedMessage id="view-team.mainDescriptionStart" />
            </div>
            <FormattedMessage id="view-team.mainDescriptionMiddle" />
            <SMainDescriptionEnd>
                <FormattedMessage id="view-team.mainDescriptionEnd" />
            </SMainDescriptionEnd>
        </SHeaderSecond>
    </Container>
);
