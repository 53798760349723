import { graphql, useStaticQuery } from 'gatsby';

import { positions } from './data';

export const useTeam = () => {
    const images = useStaticQuery(graphql`
        {
            mykhailo: file(relativePath: { eq: "team/members/mykhailo.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            bartekl: file(
                relativePath: { eq: "team/members/unnamed-man.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            aneta: file(relativePath: { eq: "team/members/aneta.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            lukasza: file(
                relativePath: { eq: "team/members/unnamed-man.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            magda: file(relativePath: { eq: "team/members/magda.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            przemeks: file(
                relativePath: { eq: "team/members/unnamed-man.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            ewa: file(relativePath: { eq: "team/members/ewa.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            marlena: file(relativePath: { eq: "team/members/unnamed-woman.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            mateusz: file(relativePath: { eq: "team/members/mateusz.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            marcin: file(relativePath: { eq: "team/members/marcin.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            patrykb: file(relativePath: { eq: "team/members/unnamed-man.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            przemek: file(relativePath: { eq: "team/members/przemek.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            malgorzata: file(relativePath: { eq: "team/members/gosia.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            oskar: file(relativePath: { eq: "team/members/unnamed-man.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            basia: file(relativePath: { eq: "team/members/basia.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            jan: file(relativePath: { eq: "team/members/jan.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            mateuszt: file(relativePath: { eq: "team/members/mateuszt.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            maciejl: file(
                relativePath: { eq: "team/members/unnamed-man.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            dawid: file(relativePath: { eq: "team/members/dawid.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            anila: file(
                relativePath: { eq: "team/members/unnamed-woman.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            mateuszr: file(relativePath: { eq: "team/members/unnamed-man.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
        }
    `);

    const passWithPositions = () => {
        const people = {};
        for (const key of Object.keys(images)) {
            people[key] = {
                ...images[key],
                ...positions[key],
            };
        }
        return people;
    };

    return passWithPositions();
};
