import React from 'react';
import styled from 'styled-components';
import { CONSTANTS } from 'constants/styles/constants';
import { GatsbyImage } from 'gatsby-plugin-image';
import { object, string } from 'prop-types';

const SContainer = styled.div`
    position: relative;
    display: flex;
    overflow: hidden;
    border-radius: 0.5rem;

    ${CONSTANTS.MEDIA.max420`
        width: 15rem;
        height: 15rem;
        margin: auto;
    `}
`;

const SLabel = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: var(--secondary-color);
    color: var(--white-color);
    opacity: 0;
    cursor: pointer;

    p {
        visibility: hidden;
        transform: translateY(255px);
        transition: ${CONSTANTS.TRANSITION.TRANSITION1};
        pointer-events: none;
    }

    &:hover,
    &:focus-visible {
        width: 100%;
        height: 100%;
        opacity: 0.85;

        p {
            visibility: visible;
            transform: translateY(0);
        }

        &:before,
        &:after {
            display: none;
        }
    }
`;

const SFullName = styled.p`
    font-weight: bold;
    font-size: 1.125rem;

    ${CONSTANTS.MEDIA.min1024max1366`
        font-size: 1rem;
    `}

    ${CONSTANTS.MEDIA.max1024`
        font-size: 1.375rem;
        padding-bottom: 0.125rem;
    `}
`;

const SPosition = styled.p`
    font-size: 1rem;

    ${CONSTANTS.MEDIA.min1024max1366`
        font-size: 0.875rem;
    `}

    ${CONSTANTS.MEDIA.max1024`
        font-size: 1.25rem;
    `}
`;

export const TeamMember = ({ fullName, image, imageAlt, position }) => (
    <SContainer>
        <GatsbyImage image={image} alt={imageAlt} />
        <SLabel tabIndex={0}>
            <SFullName>{fullName}</SFullName>
            <SPosition>{position}</SPosition>
        </SLabel>
    </SContainer>
);

TeamMember.propTypes = {
    image: object.isRequired,
    imageAlt: string.isRequired,
    position: string,
    fullName: string,
};

TeamMember.defaultProps = {
    position: '',
    fullName: '',
};
