import React from 'react';
import styled from 'styled-components';
import { CONSTANTS } from 'constants/styles/constants';
import { GatsbyImage } from 'gatsby-plugin-image';
import { object, string } from 'prop-types';

const STeamMember = styled(GatsbyImage)`
    height: auto;
    ${CONSTANTS.MEDIA.max420`
       ${(props) => props['data-style']}
    `}
`;

export const TeamMember = ({ image, className, mediaParams, alt }) => (
    <STeamMember
        image={image.childImageSharp.gatsbyImageData}
        className={className}
        data-style={mediaParams}
        alt={alt}
    />
);

TeamMember.propTypes = {
    mediaParams: string,
    image: object.isRequired,
    className: string,
    alt: string,
};

TeamMember.defaultProps = {
    className: '',
    mediaParams: '',
    alt: '',
};
