import React from 'react';
import { AboutUsView } from 'views/about-us';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/team.json';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const TeamPage = (props) => {
    const { location } = props;

    return (
        <Layout location={location} messages={messages}>
            <AboutUsView />
        </Layout>
    );
};

TeamPage.propTypes = {
    location: object.isRequired,
};

export default TeamPage;

export const Head = () => <SEO tags={TAGS.TEAM} />;
