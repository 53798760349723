import { graphql, useStaticQuery } from 'gatsby';

export const useTeamEventGallery = () => {
    const images = useStaticQuery(graphql`
        {
            image_1: file(relativePath: { eq: "team/event/event-1.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            image_2: file(relativePath: { eq: "team/event/event-2.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            image_3: file(relativePath: { eq: "team/event/event-3.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            image_4: file(relativePath: { eq: "team/event/event-4.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            image_5: file(relativePath: { eq: "team/event/event-5.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            image_6: file(relativePath: { eq: "team/event/event-6.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
        }
    `);

    return images;
};
