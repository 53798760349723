import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'components/button-new';
import { EXTERNAL_REDIRECTS } from 'constants/external-redirects';
import { PATHS } from 'constants/paths';
import { CONSTANTS } from 'constants/styles/constants';

const Container = styled.div`
    margin-top: 3rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    ${CONSTANTS.MEDIA.max600`
        flex-direction: column;
    `}
`;

const SLinkButton = styled(Button)`
    width: ${({ alt }) => (alt ? '7.5rem' : '15.75rem')};
    background-color: ${({ alt }) =>
        alt ? 'var(--additional-color)' : 'var(--white-color)'};
    border: unset;

    &:hover {
        background: ${({ alt }) =>
            alt
                ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--additional-color)'
                : 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), var(--white-color)'};
        span {
            color: ${({ alt }) =>
                alt ? 'var(--white-color)' : 'var(--black-color)'};
        }
    }

    span {
        font-size: 0.875rem;
        color: ${({ alt }) =>
            alt ? 'var(--white-color)' : 'var(--black-color)'};
    }
`;

export const ButtonLinks = () => {
    return (
        <Container>
            <SLinkButton to={PATHS.CAREERS} component="gatsby-link" alt>
                <FormattedMessage id="view-team.joinUs" />
            </SLinkButton>
            <SLinkButton
                to={EXTERNAL_REDIRECTS.CULTURE_BOOK_URL}
                target="_blank"
                component="a"
            >
                <FormattedMessage id="view-team.culture-book" />
            </SLinkButton>
        </Container>
    );
};
