import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/container';
import { UnderlinedSectionTitle } from 'components/underlined-section-title';
import { CONSTANTS } from 'constants/styles/constants';

import { ButtonLinks } from './button-links';
import { CORE_VALUES_ITEMS } from './constants';
import { CoreValueItem } from './core-value-item';

const SContainer = styled(Container)`
    margin-bottom: 12.5rem;
`;

const SItemsContainer = styled.div`
    width: 100%;
    margin-bottom: 5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 5rem;
    row-gap: 6.5625rem;
    align-items: center;

    & div > div:nth-child(even) {
        background: var(--additional-color);
    }

    ${CONSTANTS.MEDIA.max700`
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        gap: 3.75rem;

        & > div {
            > :last-child {
                padding: 0 5rem;
            }
        }
    `}

    ${CONSTANTS.MEDIA.max500`
        & > div {
            > :last-child {
                padding: 0 2rem;
            }
        }
    `}
`;

export const CoreValues = () => {
    const resolveCoreValues = () => {
        return CORE_VALUES_ITEMS.map((item) => {
            const { title } = item;
            return <CoreValueItem key={title} {...item} />;
        });
    };

    return (
        <SContainer>
            <UnderlinedSectionTitle label="view-team.core-values.title" />
            <SItemsContainer>{resolveCoreValues()}</SItemsContainer>
            <ButtonLinks />
        </SContainer>
    );
};
