export const MEDIA_QUERIES = {
    LAPTOP_M: '1280',
    LAPTOP: '1024',
    LAPTOP_S: '900',
    TABLET: '768',
    TABLET_S: '660',
    MOBILE: '500',
};

export const FIRST_IMAGE_PARAMS = {
    scalingSettings: {
        [MEDIA_QUERIES.LAPTOP_M]: {
            common: 0.8,
            childBottom: 0.815,
            radialGradientLeft: 0.785,
        },
        [MEDIA_QUERIES.LAPTOP]: {
            common: 0.7,
            childBottom: 0.725,
            radialGradientLeft: 0.675,
        },
        [MEDIA_QUERIES.LAPTOP_S]: {
            common: 1,
            radialGradient: 0.9,
            radialGradientBottom: '-75%',
            radialGradientLeft: '50%',
        },
        [MEDIA_QUERIES.TABLET]: {
            common: 0.9,
            childBottom: 0.91,
            radialGradient: 0.75,
            radialGradientBottom: '-60%',
        },
        [MEDIA_QUERIES.TABLET_S]: {
            common: 0.7,
            childBottom: 0.725,
            radialGradient: 0.6,
            radialGradientBottom: '-55%',
        },
        [MEDIA_QUERIES.MOBILE]: {
            common: 0.52,
            childBottom: 0.59,
            radialGradient: 0.44,
            radialGradientBottom: '-35%',
        },
    },
    dimensions: {
        CONTAINER_WIDTH: '39.625rem',
        CONTAINER_HEIGHT: '20rem',
        CONTAINER_BORDER_RADIUS: '12.90625rem',
        CONTAINER_CHILD_WIDTH: '33.1875rem',
        CONTAINER_CHILD_HEIGHT: '24.875rem',
        CONTAINER_CHILD_BOTTOM: '5.3125rem',
        CONTAINER_CHILD_LEFT: '3.375rem',
        RADIAL_GRADIENT_DIMENSIONS: '52.25rem',
        RADIAL_GRADIENT_LEFT: '26rem',
    },
};

export const SECOND_IMAGE_PARAMS = {
    scalingSettings: {
        [MEDIA_QUERIES.LAPTOP_M]: {
            common: 0.8,
            childBottom: 0.815,
            radialGradient: 0.7,
            radialGradientLeft: 1.05,
        },
        [MEDIA_QUERIES.LAPTOP]: {
            common: 0.7,
            childBottom: 0.725,
            radialGradient: 0.55,
        },
        [MEDIA_QUERIES.LAPTOP_S]: {
            common: 1,
            radialGradient: 0.94,
            radialGradientBottom: '-60%',
            radialGradientLeft: '50%',
        },
        [MEDIA_QUERIES.TABLET]: {
            common: 0.9,
            childBottom: 0.91,
            radialGradient: 0.8,
            radialGradientBottom: '-45%',
        },
        [MEDIA_QUERIES.TABLET_S]: {
            common: 0.7,
            childBottom: 0.725,
            radialGradient: 0.6,
            radialGradientBottom: '-40%',
        },
        [MEDIA_QUERIES.MOBILE]: {
            common: 0.55,
            childBottom: 0.61,
            radialGradient: 0.45,
            radialGradientBottom: '-30%',
        },
    },
    dimensions: {
        CONTAINER_WIDTH: '39.25rem',
        CONTAINER_HEIGHT: '23.5625rem',
        CONTAINER_BORDER_RADIUS: '12.90625rem',
        CONTAINER_CHILD_WIDTH: '38.875rem',
        CONTAINER_CHILD_HEIGHT: '25.8125rem',
        CONTAINER_CHILD_BOTTOM: '2.625rem',
        CONTAINER_CHILD_LEFT: '0',
        RADIAL_GRADIENT_DIMENSIONS: '51rem',
        RADIAL_GRADIENT_LEFT: '68%',
    },
};
