import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { CONSTANTS } from 'constants/styles/constants';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';

const SContainer = styled(Container)`
    margin-bottom: 0;

    ${CONSTANTS.MEDIA.max420`
        padding: 0;
    `}
`;

const SGatsbyImage = styled(GatsbyImage)`
    display: block;
    width: 100%;

    ${CONSTANTS.MEDIA.max420`
        height: 23.125rem;

        * > img {
            object-fit: fill !important
        }
    `}
`;

export const FooterImage = () => {
    const { formatMessage } = useIntl();
    const data = useStaticQuery(graphql`
        {
            teamDesktop: file(
                relativePath: { eq: "team/team-comics-back.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
            teamMobile: file(
                relativePath: { eq: "team/team-comics-back-mobile.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
        }
    `);

    const images = withArtDirection(getImage(data.teamDesktop), [
        {
            media: '(max-width: 420px)',
            image: getImage(data.teamMobile),
        },
    ]);

    return (
        <SContainer>
            <SGatsbyImage
                image={images}
                alt={formatMessage({
                    id: 'view-team.teamMembersBackAlt',
                })}
                imgStyle={{
                    objectFit: 'contain',
                    width: '100%',
                }}
            />
        </SContainer>
    );
};
