import React from 'react';
import Communication from 'svgs/team/communication.svg';
import DiscussionLike from 'svgs/team/discussion-like.svg';
import Honesty from 'svgs/team/honesty.svg';
import Involvement from 'svgs/team/involvement.svg';
import MaturityForQuality from 'svgs/team/maturity-for-quality.svg';
import OpennessToChanges from 'svgs/team/openness-to-changes.svg';
import Responsibility from 'svgs/team/responsibility.svg';
import SelfImprovement from 'svgs/team/self-improvement.svg';
import Supportive from 'svgs/team/supportive.svg';

const ROOT_PATH = 'view-team.core-values';

export const CORE_VALUES_ITEMS = [
    {
        title: `${ROOT_PATH}.communication.title`,
        info: `${ROOT_PATH}.communication.info`,
        svg: <Communication />,
    },
    {
        title: `${ROOT_PATH}.involvement.title`,
        info: `${ROOT_PATH}.involvement.info`,
        svg: <Involvement />,
    },
    {
        title: `${ROOT_PATH}.supportive.title`,
        info: `${ROOT_PATH}.supportive.info`,
        svg: <Supportive />,
    },
    {
        title: `${ROOT_PATH}.self-improvement.title`,
        info: `${ROOT_PATH}.self-improvement.info`,
        svg: <SelfImprovement />,
    },
    {
        title: `${ROOT_PATH}.discussion-like.title`,
        info: `${ROOT_PATH}.discussion-like.info`,
        svg: <DiscussionLike />,
    },
    {
        title: `${ROOT_PATH}.openness-to-changes.title`,
        info: `${ROOT_PATH}.openness-to-changes.info`,
        svg: <OpennessToChanges />,
    },
    {
        title: `${ROOT_PATH}.maturity-for-quality.title`,
        info: `${ROOT_PATH}.maturity-for-quality.info`,
        svg: <MaturityForQuality />,
    },
    {
        title: `${ROOT_PATH}.responsibility.title`,
        info: `${ROOT_PATH}.responsibility.info`,
        svg: <Responsibility />,
    },
    {
        title: `${ROOT_PATH}.honesty.title`,
        info: `${ROOT_PATH}.honesty.info`,
        svg: <Honesty />,
    },
];
