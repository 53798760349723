import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { HeaderThird } from 'components/header-third';
import { Paragraph } from 'components/paragraph';

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    color: var(--white-color);

    & > svg {
        position: absolute;
        top: 1.96875rem;
        width: 2.625rem;
        height: 2.625rem;
    }
`;

const SItemTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 1.75rem;
    gap: 1.75rem;
`;

const SSvgBackground = styled.div`
    width: 6.125rem;
    height: 6.125rem;
    border-radius: 50%;
    background: var(--tertiary-color);
    opacity: 0.1;
`;

const SParagraph = styled(Paragraph)`
    font-size: 1rem;

    > span {
        &:first-child {
            display: grid;
        }
    }
`;

export const CoreValueItem = ({ title, info, svg }) => {
    return (
        <Container>
            {svg}
            <SSvgBackground />
            <SItemTextContainer>
                <HeaderThird>
                    <FormattedMessage id={title} />
                </HeaderThird>
                <SParagraph>
                    <FormattedMessage id={info} />
                </SParagraph>
            </SItemTextContainer>
        </Container>
    );
};
