import React from 'react';
import styled from 'styled-components';
import TeamSection from 'components/fellowship-of-the-ring';
import { CONSTANTS } from 'constants/styles/constants';

import { CoreValues } from './components/core-values';
import { Description } from './components/description';
import { FooterImage } from './components/footer-image';
import { GeneralInfoSection } from './components/general-info-section';
import { Team } from './components/team';
import { TeamEventGallery } from './components/team-event-gallery';

const SAboutUsView = styled.div`
    width: 100%;
    padding-top: ${CONSTANTS.MARGIN.PAGE_BIG};
    background-color: var(--secondary-color);
`;

export const AboutUsView = () => {
    return (
        <SAboutUsView>
            <Description />
            <GeneralInfoSection />
            <CoreValues />
            <TeamEventGallery />
            <Team />
            <TeamSection />
            <FooterImage />
        </SAboutUsView>
    );
};
