import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { Paragraph } from 'components/paragraph';
import { UnderlinedSectionTitle } from 'components/underlined-section-title';
import { CONSTANTS } from 'constants/styles/constants';
import { useTeam } from 'hooks/useTeam';

import { TeamMember } from './team-member';

const SItemsContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1.5rem;
    align-items: center;

    ${CONSTANTS.MEDIA.max1200` 
        grid-template-columns: repeat(5, 1fr);
    `}

    ${CONSTANTS.MEDIA.max1024` 
        grid-template-columns: repeat(4, 1fr);
    `}

    ${CONSTANTS.MEDIA.max768` 
        grid-template-columns: repeat(3, 1fr);
    `}

    ${CONSTANTS.MEDIA.max600` 
        grid-template-columns: repeat(2, 1fr);
    `}

    ${CONSTANTS.MEDIA.max420` 
        grid-template-columns: 1fr;
    `}
`;

const STitleContainer = styled.div`
    text-align: center;
    color: var(--white-color);
    margin-bottom: 3.75rem;

    & > h2 {
        margin-bottom: 1.5rem;
    }
`;

export const Team = () => {
    const people = useTeam();

    const resolveTeam = () =>
        Object.keys(people).map((key, index) => (
            <TeamMember
                key={`${key}_${index}_${people[key].name}_${people[key].role}`}
                position={people[key].role}
                fullName={people[key].name}
                hobby={people[key].hobby}
                image={people[key].childImageSharp.gatsbyImageData}
                imageAlt={`${people[key].name} - ${people[key].role}`}
            />
        ));

    return (
        <Container>
            <STitleContainer>
                <UnderlinedSectionTitle label="view-team.meetTheTeam" />
                <Paragraph>
                    <FormattedMessage id="view-team.fantasticSpecialists" />
                </Paragraph>
            </STitleContainer>
            <SItemsContainer>{resolveTeam()}</SItemsContainer>
        </Container>
    );
};
