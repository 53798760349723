import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/container';
import { CheckOurTeam } from 'components/fellowship-of-the-ring/components/check-our-team';
import { TheFellowshipOfTheRing } from 'components/fellowship-of-the-ring/components/the-fellowship-of-the-ring';
import { CONSTANTS } from 'constants/styles/constants';

const SContainer = styled(Container)`
    padding-top: 2.75rem;
`;

const SSubContainer = styled.div`
    width: 100%;
    height: 19.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid var(--additional-color);
    border-radius: 9.625rem;

    ${CONSTANTS.MEDIA.max1024`
        height: 35rem;
        flex-direction: column-reverse;
        padding-top: 1rem;
        border-radius: 7.625rem;
    `}

    ${CONSTANTS.MEDIA.max700`
        border-radius: 4rem;
    `}
`;

const TeamSection = () => {
    return (
        <SContainer>
            <SSubContainer>
                <TheFellowshipOfTheRing />
                <CheckOurTeam />
            </SSubContainer>
        </SContainer>
    );
};

export default TeamSection;
