import { css } from 'styled-components';

const scaler = (value, scale) => `calc(${value} * ${scale})`;

const dimensionsScaler = ({
    dimensions,
    common,
    childBottom,
    radialGradient,
    radialGradientLeft,
    radialGradientBottom,
}) => {
    const {
        CONTAINER_WIDTH,
        CONTAINER_HEIGHT,
        CONTAINER_BORDER_RADIUS,
        CONTAINER_CHILD_WIDTH,
        CONTAINER_CHILD_HEIGHT,
        CONTAINER_CHILD_BOTTOM,
        CONTAINER_CHILD_LEFT,
        RADIAL_GRADIENT_DIMENSIONS,
        RADIAL_GRADIENT_LEFT,
    } = dimensions;

    return css`
        max-width: ${scaler(CONTAINER_WIDTH, common)};
        max-height: ${scaler(CONTAINER_HEIGHT, common)};
        border-radius: ${scaler(CONTAINER_BORDER_RADIUS, common)};

        > :first-child {
            width: ${scaler(CONTAINER_CHILD_WIDTH, common)};
            height: ${scaler(CONTAINER_CHILD_HEIGHT, common)};
            bottom: ${scaler(CONTAINER_CHILD_BOTTOM, childBottom ?? common)};
            left: ${scaler(CONTAINER_CHILD_LEFT, common)};
        }

        > :last-child {
            width: ${scaler(
                RADIAL_GRADIENT_DIMENSIONS,
                radialGradient ?? common,
            )};
            height: ${scaler(
                RADIAL_GRADIENT_DIMENSIONS,
                radialGradient ?? common,
            )};
            left: ${typeof radialGradientLeft === 'string'
                ? radialGradientLeft
                : scaler(RADIAL_GRADIENT_LEFT, radialGradientLeft)};
            bottom: ${radialGradientBottom};
        }
    `;
};

export const scaleResolver = ({ dimensions, scalingSettings }, mediaQuerry) => {
    const props = {
        dimensions,
        ...scalingSettings[mediaQuerry],
    };
    return dimensionsScaler(props);
};
