import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import { string } from 'prop-types';

const CONTAINER_BOTTOM = '78px';

const SContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    bottom: 84px;

    ${CONSTANTS.MEDIA.max1280`
        bottom: calc(${CONTAINER_BOTTOM} * 0.8);
    `}

    ${CONSTANTS.MEDIA.max1024`
        bottom: calc(${CONTAINER_BOTTOM} * 0.725);
    `}

    ${CONSTANTS.MEDIA.max900`
        bottom: 0;
        position: unset;
    `}
`;

const SHeaderSecond = styled(HeaderSecond)`
    margin-bottom: 1.5rem;
    color: var(--white-color);

    ${CONSTANTS.MEDIA.max420`
        padding: 0 0.5rem;
    `}
`;

const SParagraph = styled(Paragraph)`
    color: var(--white-color);
    line-height: 1.75rem;
    margin-bottom: 1.5rem;

    ${CONSTANTS.MEDIA.max420`
        padding: 0 0.5rem;
    `}
`;

export const SectionInfo = ({ title, firstSection, secondSection }) => {
    return (
        <SContainer>
            <SHeaderSecond>
                <FormattedMessage id={title} />
            </SHeaderSecond>
            <SParagraph>
                <FormattedMessage id={firstSection} />
            </SParagraph>
            <SParagraph>
                <FormattedMessage id={secondSection} />
            </SParagraph>
        </SContainer>
    );
};

SectionInfo.propTypes = {
    title: string,
    info: string,
};

SectionInfo.defaultProps = {
    title: '',
    info: '',
};
