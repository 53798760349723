import React from 'react';
import styled from 'styled-components';
import { TeamMember } from 'components/fellowship-of-the-ring/components/team-member';
import { CONSTANTS } from 'constants/styles/constants';
import { graphql, useStaticQuery } from 'gatsby';

const STeamBack = styled(TeamMember)`
    left: -5rem;
    bottom: 1.625rem;

    ${CONSTANTS.MEDIA.max1200`
        left: -2rem;
    `}

    ${CONSTANTS.MEDIA.max1024`
        left: 0;
        width: 30rem;
        bottom: -2.4rem;
    `}

    ${CONSTANTS.MEDIA.max700`
        width: 24rem;
        bottom: -4.1rem;
    `}

    ${CONSTANTS.MEDIA.max500`
        width: 20.375rem;
        bottom: -5.1rem;
    `}
`;

export const TheFellowshipOfTheRing = () => {
    const images = useStaticQuery(graphql`
        {
            teamBackSingle: file(
                relativePath: { eq: "team/team-back-double.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
        }
    `);
    return (
        <STeamBack image={images.teamBackSingle} alt="Two Team Members Back" />
    );
};
