import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { HeaderSecond } from 'components/header-second';

const SHeaderSecond = styled(HeaderSecond)`
    text-align: center;
    color: var(--white-color);
    margin-bottom: 5rem;
`;

export const UnderlinedSectionTitle = ({ label }) => (
    <SHeaderSecond>
        <FormattedMessage id={label} />
    </SHeaderSecond>
);
