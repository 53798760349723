import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'components/button-new';
import { HeaderFourth } from 'components/header-fourth';
import { HeaderSecond } from 'components/header-second';
import { PATHS } from 'constants/paths';
import { CONSTANTS } from 'constants/styles/constants';

const SWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${CONSTANTS.MEDIA.max1024`
        margin: 1rem 0 2rem;
        text-align: center;
    `}
`;

const SHeaderFourth = styled(HeaderFourth)`
    color: var(--additional-color);
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 700;
    margin-bottom: 0.75rem;
`;

const SHeaderSecond = styled(HeaderSecond)`
    white-space: pre-wrap;
    margin-bottom: 2rem;
    color: var(--white-color);
`;

const SFilledButton = styled(Button)`
    max-width: 14rem;
    width: 100%;
    display: flex;
    margin-right: 1rem;
    background-color: var(--additional-color);
    border: unset;

    &:hover {
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
            ),
            var(--additional-color);
    }
`;

export const CheckOurTeam = () => {
    return (
        <SWrapper>
            <SHeaderFourth>
                <FormattedMessage id="view-team.careers" />
            </SHeaderFourth>
            <SHeaderSecond>
                <FormattedMessage id="view-team.wantToJoin" />
            </SHeaderSecond>
            <SFilledButton
                id="fellowship-of-the-ring-be-part-of-us"
                to={PATHS.CAREERS}
                component="gatsby-link"
            >
                <FormattedMessage id="view-team.seeOpenPositions" />
            </SFilledButton>
        </SWrapper>
    );
};
