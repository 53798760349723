export const positions = {
    mateusz: {
        name: 'Mateusz',
        role: 'Co-Founder & CEO',
    },
    marcin: {
        name: 'Marcin',
        role: 'Co-Founder & CTO',
    },
    dawid: {
        name: 'Dawid',
        role: 'Frontend and Mobile Specialist',
    },
    przemeks: {
        name: 'Przemysław',
        role: 'JS Frontend Specialist',
    },
    aneta: {
        name: 'Aneta',
        role: 'HR & Employer Branding Manager',
    },
    mykhailo: {
        name: 'Mykhailo',
        role: 'Frontend and Mobile Specialist',
    },
    przemek: {
        name: 'Przemek',
        role: 'UX/UI Specialist',
    },
    basia: {
        name: 'Basia',
        role: 'QA Specialist',
    },
    mateuszt: {
        name: 'Mateusz',
        role: 'Backend and AWS Specialist',
    },
    magda: {
        name: 'Magdalena',
        role: 'Tech Leader & Frontend Specialist',
    },
    jan: {
        name: 'Jan',
        role: 'Backend and Blockchain Specialist',
    },
    malgorzata: {
        name: 'Gosia',
        role: 'Full-stack JS Specialist',
    },
    ewa: {
        name: 'Ewa',
        role: 'HR & Administration Specialist',
    },
    lukasza: {
        name: 'Łukasz',
        role: 'Project & Delivery Manager',
    },
    bartekl: {
        name: 'Bartłomiej',
        role: 'Backend and Blockchain Specialist',
    },
    maciejl: {
        name: 'Maciej',
        role: 'DevOps and AWS Specialist',
    },
    anila: {
        name: 'Anila',
        role: 'Content Marketing Specialist',
    },
    patrykb: {
        name: 'Patryk',
        role: 'Frontend and Mobile Specialist',
    },
    marlena: {
        name: 'Marlena',
        role: 'QA Specialist',
    },
    mateuszr: {
        name: 'Konrad',
        role: 'Full-Stack JS Specialist',
    },
    oskar: {
        name: 'Oskar',
        role: 'JS Backend Specialist',
    },

};
