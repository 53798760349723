import React from 'react';
import styled from 'styled-components';
import { CONSTANTS } from 'constants/styles/constants';
import { GatsbyImage } from 'gatsby-plugin-image';
import { bool, object, string } from 'prop-types';

import { MEDIA_QUERIES } from './constants';
import { scaleResolver } from './utilities';

const SContainer = styled.div`
    display: flex;
    flex: 1.55;
    z-index: ${CONSTANTS.ZINDEX.NORMAL};
    border: ${({ secondary }) =>
        `3px solid var(${
            secondary ? '--tertiary-color' : '--additional-color'
        })`};

    max-width: ${({ dimensions: { CONTAINER_WIDTH } }) => CONTAINER_WIDTH};
    max-height: ${({ dimensions: { CONTAINER_HEIGHT } }) => CONTAINER_HEIGHT};
    border-radius: ${({ dimensions: { CONTAINER_BORDER_RADIUS } }) =>
        CONTAINER_BORDER_RADIUS};

    > :first-child {
        width: ${({ dimensions: { CONTAINER_CHILD_WIDTH } }) =>
            CONTAINER_CHILD_WIDTH};
        height: ${({ dimensions: { CONTAINER_CHILD_HEIGHT } }) =>
            CONTAINER_CHILD_HEIGHT};
        bottom: ${({ dimensions: { CONTAINER_CHILD_BOTTOM } }) =>
            CONTAINER_CHILD_BOTTOM};
        left: ${({ dimensions: { CONTAINER_CHILD_LEFT } }) =>
            CONTAINER_CHILD_LEFT};
    }

    ${CONSTANTS.MEDIA.max1280`
        ${(props) => scaleResolver(props, MEDIA_QUERIES.LAPTOP_M)};
    `}

    ${CONSTANTS.MEDIA.max1024`
        ${(props) => scaleResolver(props, MEDIA_QUERIES.LAPTOP)};
    `}

    ${CONSTANTS.MEDIA.max900`
        order: 2;
        align-self: center;
        width: 100%;

        ${(props) => scaleResolver(props, MEDIA_QUERIES.LAPTOP_S)};
    `}

    ${CONSTANTS.MEDIA.max768`
        ${(props) => scaleResolver(props, MEDIA_QUERIES.TABLET)};
    `}

    ${CONSTANTS.MEDIA.max660`
        ${(props) => scaleResolver(props, MEDIA_QUERIES.TABLET_S)};
    `}

    ${CONSTANTS.MEDIA.max500`
        ${(props) => scaleResolver(props, MEDIA_QUERIES.MOBILE)};
    `}
`;

const SRadialGradient = styled.div`
    width: ${({ dimensions: { RADIAL_GRADIENT_DIMENSIONS } }) =>
        RADIAL_GRADIENT_DIMENSIONS};
    height: ${({ dimensions: { RADIAL_GRADIENT_DIMENSIONS } }) =>
        RADIAL_GRADIENT_DIMENSIONS};
    position: absolute;
    z-index: ${CONSTANTS.ZINDEX.LOWEST};
    transform: translate(-50%, -50%);
    left: ${({ dimensions: { RADIAL_GRADIENT_LEFT } }) => RADIAL_GRADIENT_LEFT};
    background-image: ${({ secondary }) =>
        `radial-gradient(at 50% 50%, ${
            secondary
                ? 'hsla(302, 64%, 36%, 0.25) 0'
                : 'hsla(187, 57%, 53%, 0.25) 0'
        }, transparent 70%)`};
`;

export const FramedRadialGradientImage = ({
    image,
    imageAlt,
    dimensions,
    scalingSettings,
    secondary,
}) => {
    const { RADIAL_GRADIENT_DIMENSIONS, RADIAL_GRADIENT_LEFT } = dimensions;
    return (
        <SContainer
            dimensions={dimensions}
            scalingSettings={scalingSettings}
            secondary={secondary}
        >
            <GatsbyImage image={image} alt={imageAlt} />
            <SRadialGradient
                dimensions={{
                    RADIAL_GRADIENT_DIMENSIONS,
                    RADIAL_GRADIENT_LEFT,
                }}
                secondary={secondary}
            />
        </SContainer>
    );
};

FramedRadialGradientImage.propTypes = {
    image: object.isRequired,
    imageAlt: string.isRequired,
    dimensions: object.isRequired,
    scalingSettings: object.isRequired,
    secondary: bool,
};

FramedRadialGradientImage.defaultProps = {
    image: '',
    imageAlt: '',
    secondary: false,
};
